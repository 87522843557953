


























































































































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import { Group, TextStrValue } from './types';
const Ripple = require('vue-ripple-directive');
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default Vue.extend({
  directives: {
    Ripple,
  },

  props: {
    isNew: Boolean,
    currentGroup: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const group: Group = {
      _id: '',
      name: '',
      admin: false,
      monitors: [],
      monitorTypes: [],
      abilities: [],
      parent: undefined,
      lineToken: undefined,
      lineNotifyColdPeriod: 30,
      county: undefined,
    };

    const abilityOptions = [
      {
        text: '儀表板',
        value: {
          action: 'read',
          subject: 'Dashboard',
        },
      },
      {
        text: '資料',
        value: {
          action: 'read',
          subject: 'Data',
        },
      },
      {
        text: '設定警報',
        value: {
          action: 'set',
          subject: 'Alarm',
        },
      },
    ];

    const countyOptions = [
      { value: undefined, text: '請選擇一個縣市' },
      { value: '基隆市', text: '基隆市' },
      { value: '臺北市', text: '臺北市' },
      { value: '新北市', text: '新北市' },
      { value: '桃園市', text: '桃園市' },
      { value: '新竹市', text: '新竹市' },
      { value: '新竹縣', text: '新竹縣' },
      { value: '苗栗縣', text: '苗栗縣' },
      { value: '臺中市', text: '臺中市' },
      { value: '彰化縣', text: '彰化縣' },
      { value: '南投縣', text: '南投縣' },
      { value: '雲林縣', text: '雲林縣' },
      { value: '嘉義市', text: '嘉義市' },
      { value: '嘉義縣', text: '嘉義縣' },
      { value: '臺南市', text: '臺南市' },
      { value: '高雄市', text: '高雄市' },
      { value: '屏東縣', text: '屏東縣' },
      { value: '宜蘭縣', text: '宜蘭縣' },
      { value: '花蓮縣', text: '花蓮縣' },
      { value: '臺東縣', text: '臺東縣' },
      { value: '澎湖縣', text: '澎湖縣' },
      { value: '金門縣', text: '金門縣' },
      { value: '連江縣', text: '連江縣' },
    ];
    return {
      group,
      abilityOptions,
      countyOptions,
      groupList: Array<Group>(),
    };
  },
  computed: {
    ...mapState('monitorTypes', ['monitorTypes']),
    ...mapState('monitors', ['monitors']),
    ...mapGetters('monitors', ['mMap']),
    btnTitle(): string {
      if (this.isNew) return '新增';
      return '更新';
    },
    monitorOptions(): Array<any> {
      const ret = Array<TextStrValue>();
      let monitors = this.monitors;
      if (this.group.parent) {
        const parentGroup = this.groupList.find((group, index) => {
          return group._id === this.group.parent;
        }) as Group;

        monitors = this.monitors.filter((m: any) => {
          if (parentGroup && parentGroup.monitors)
            return parentGroup.monitors.indexOf(m._id) !== -1;
          return false;
        });
      }

      for (const m of monitors) ret.push({ text: m.desc, value: m._id });
      return ret;
    },
    monitorTypeOptions(): Array<TextStrValue> {
      const ret = Array<TextStrValue>();

      let monitorTyes: Array<any> = this.monitorTypes;
      if (this.group.parent) {
        const parentGroup = this.groupList.find((group, index) => {
          return group._id === this.group.parent;
        }) as Group;

        monitorTyes = this.monitorTypes.filter((mt: any) => {
          if (parentGroup && parentGroup.monitorTypes)
            return parentGroup.monitorTypes.indexOf(mt._id) !== -1;
          return false;
        });
      }

      for (const mt of monitorTyes) ret.push({ text: mt.desp, value: mt._id });

      return ret;
    },
    canUpsert(): boolean {
      if (!this.group._id) return false;
      if (!this.group.name) return false;
      return true;
    },
  },
  mounted() {
    this.copyProp(this.group);
    this.getGroupList();
  },
  methods: {
    copyProp(group: Group): void {
      if (!this.isNew) {
        const self = this.currentGroup;
        group._id = self._id;
        group.name = self.name;
        group.admin = self.admin;
        group.monitors = self.monitors;
        group.monitorTypes = self.monitorTypes;
        group.abilities = self.abilities;
        group.parent = self.parent;
        group.lineToken = self.lineToken;
        group.lineNotifyColdPeriod = self.lineNotifyColdPeriod;
        group.county = self.county;
      }
    },
    reset() {
      this.copyProp(this.group);
    },
    testLineMessage() {
      axios.get(`/TestLINE/${this.group.lineToken}`).then(res => {
        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '成功',
              icon: 'GroupIcon',
            },
          });
        } else {
          this.$bvModal.msgBoxOk('失敗', { headerBgVariant: 'danger' });
        }
      });
    },
    async getGroupList() {
      const res = await axios.get('/Groups');
      if (res.status == 200) this.groupList = res.data;
    },
    sanityCheck() {
      this.group.monitors = this.group.monitors.filter(m => this.mMap.get(m));
    },
    upsert() {
      this.sanityCheck();
      if (this.isNew) {
        axios.post(`/Group`, this.group).then(res => {
          if (res.status === 200) {
            this.$bvModal.msgBoxOk('成功', { headerBgVariant: 'primary' });
          } else {
            this.$bvModal.msgBoxOk('失敗', { headerBgVariant: 'danger' });
          }
          this.$emit('created');
        });
      } else {
        axios.put(`/Group/${this.currentGroup._id}`, this.group).then(res => {
          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '成功',
                icon: 'GroupIcon',
              },
            });
          } else {
            this.$bvModal.msgBoxOk('失敗', { headerBgVariant: 'danger' });
          }
          this.$emit('updated');
        });
      }
    },
  },
});
